<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="9" offset-lg="1" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Organiation Name" vid="org_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="org_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="horsePower.org_id"
                      :options="organizationList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Pump Name" vid="pump_type_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="org_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('irrigation_config.pump_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="horsePower.pump_type_id"
                      :options="pumpTypeList"
                      id="pump_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Horse Power" vid="horse_power" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="horse_power"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{$t('irrigation_config.horse_power')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="horse_power"
                      v-model="horsePower.horse_power"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Horse Power (bn)" vid="horse_power_bn" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="horse_power_bn"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{$t('irrigation_config.horse_power_bn')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="horse_power_bn"
                      v-model="horsePower.horse_power_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl, irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { horsePowerStore, horsePowerUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getHorsePowerData()
      Object.freeze(tmp)
      this.horsePower = tmp
    }
    this.getOrganizationlist()
    // this.getPumpTypelist()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      horsePower: {
        org_id: 0,
        pump_type_id: 0,
        horse_power: '',
        horse_power_bn: ''
      },
      organizationList: [],
      pumpTypeList: []
    }
  },
  computed: {
  },
  watch: {
    'horsePower.org_id': function (NewId, OldId) {
      this.pumpTypeList = this.getPumpTypelist(NewId)
    }
  },
  methods: {
    getHorsePowerData () {
      return this.$store.state.IrriConfig.horsePowers.find(item => item.id === this.id)
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.horsePower.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${horsePowerUpdate}/${this.id}`, this.horsePower)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, horsePowerStore, this.horsePower)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        // this.$toast.error({
        //   title: 'Error',
        //   message: 'Operation failed! Please, try again.'
        // })
      }
    },
    getOrganizationlist () {
      RestApi.getData(commonServiceBaseUrl, 'common/org-list').then(response => {
        this.organizationList = response.data.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.org_name_bn }
          } else {
            return { value: obj.id, text: obj.org_name }
          }
        })
      })
    },
    getPumpTypelist (orgId) {
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-type/list').then(response => {
        const pumTypeObject = response.data.data.filter(data => data.org_id === orgId)
        this.pumpTypeList = pumTypeObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.pump_type_name_bn }
          } else {
            return { value: obj.id, text: obj.pump_type_name }
          }
        })
      })
    }
  }
}
</script>
