<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-col>
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('org_pro.org_name')"
              label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationtList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.pump_type')"
              label-for="pump_type_id"
            >
            <b-form-select
              plain
              v-model="search.pump_type_id"
              :options="pumpTypeList"
              id="pump_type_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.horse_power')"
              label-for="horse_power"
            >
              <b-form-input
                id="horse_power"
                v-model="search.horse_power"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irrigation_config.horse_power_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ data.item.org_name }}</span>
                    </template>
                    <template v-slot:cell(org_name_bn)="data">
                      {{ data.item.org_name_bn }}
                    </template>
                    <template v-slot:cell(pump_type_name)="data">
                      {{ data.item.pump_type_name }}
                    </template>
                    <template v-slot:cell(pump_type_name_bn)="data">
                      {{ data.item.pump_type_name_bn }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)"><i class="fas fa-pen"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="paginationDataLoad"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="localId" :key="localId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import FormV from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { horsePowerList, horsePowerToggleStatus } from '../../api/routes'

export default {
  name: 'UiDataTable',
  components: {
    FormV
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        horse_power: '',
        pump_type_id: 0,
        org_id: 0
      },
      localId: 0,
      rows: [],
      pumpTypeList: []
    }
  },
  computed: {
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
       return (this.localId === 0) ? this.$t('irrigation_config.horse_power_entry') : this.$t('irrigation_config.horse_power_modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.IrriConfig.horsePowers
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('org_pro.organization'), class: 'text-left' },
          { label: this.$t('irrigation_config.pump_type_name'), class: 'text-left' },
          { label: this.$t('irrigation_config.horse_power'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'pump_type_name_bn' },
          { key: 'horse_power_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'pump_type_name' },
          { key: 'horse_power' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
    this.getPumpTypelist()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.localId = 0
    },
    paginationDataLoad () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        horse_power: '',
        horse_power_bn: '',
        pump_type_id: '',
        org_id: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.localId = item.id
    },
    toggleStatus (item) {
      RestApi.deleteData(irriSchemeServiceBaseUrl, `${horsePowerToggleStatus}/${item.id}`).then(response => {
        if (response.success) {
          this.$store.dispatch('IrriConfig/updateHorsePowerStatus', item)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.update_msg'),
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(irriSchemeServiceBaseUrl, horsePowerList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('IrriConfig/addHorsePowerList', this.dataList(response.data.data))
          this.paginationData(response.data)
        }
      })
      //
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    dataList (data) {
      const orgList = this.$store.state.orgList
      let tmpData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
        const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    getPumpTypelist () {
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-type/list').then(response => {
        this.pumpTypeList = response.data.data.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.pump_type_name_bn }
          } else {
            return { value: obj.id, text: obj.pump_type_name }
          }
        })
      })
    }
  }
}
</script>
